<template>
  <div class="header-buttons">
    <ButtonComponent @click="editCard(0)" mod="gradient-bg">Создать</ButtonComponent>
    <ExportButton :disabled="exportInProgress" @exportExcel="exportExcel" @exportWord="exportWord" />
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ExportButton from '@/common/components/redesigned/ExportButton.vue';

  export default {
    emits: ['editCard', 'exportExcel', 'exportWord'],
    components: {
      ButtonComponent,
      ExportButton,
    },
    props: {
      exportInProgress: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      editCard(id) {
        this.$emit('editCard', id);
      },
      exportExcel() {
        this.$emit('exportExcel');
      },
      exportWord() {
        this.$emit('exportWord');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header-buttons {
    position: relative;
    display: flex;
    width: max-content;
    gap: 16px;
  }
</style>
