<template>
  <div class="export-buttons" v-click-outside="closeExportButton">
    <ButtonComponent @click="toggleExport" :disabled="disabled" class="export-buttons__button" :mod="modButton">
      <slot name="buttonHead">{{ nameButton }}</slot>
    </ButtonComponent>
    <Transition>
      <div class="export-buttons__export-type-btn-list" v-if="isVisibleExportButtons">
        <slot name="list">
          <ButtonComponent class="export-buttons__export-btn" @click="exportWord" :disabled="disabled">
            Word (.docx)
          </ButtonComponent>
          <ButtonComponent class="export-buttons__export-btn" @click="exportExcel" :disabled="disabled">
            Excel (.xlsx)
          </ButtonComponent>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';

  export default {
    emits: ['exportExcel', 'exportWord', 'toggleExport'],
    components: {
      ButtonComponent,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      nameButton: {
        type: String,
        default: 'Экспорт списка',
      },
      modButton: {
        type: String,
      },
    },
    data() {
      return {
        isVisibleExportButtons: false,
      };
    },
    methods: {
      toggleExport() {
        this.isVisibleExportButtons = !this.isVisibleExportButtons;
        this.$emit('toggleExport', this.isVisibleExportButtons);
      },
      closeExportButton() {
        this.isVisibleExportButtons = false;
      },
      exportExcel() {
        this.closeExportButton();
        this.$emit('exportExcel');
      },
      exportWord() {
        this.closeExportButton();
        this.$emit('exportWord');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .export-buttons {
    position: relative;
    display: inline-block;

    &__export-type-btn-list {
      width: 100%;
      position: absolute;
      right: 0;
      top: calc(100% + 4px);
      display: flex;
      flex-direction: column;
      gap: 4px;
      z-index: 10;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: all 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
</style>
